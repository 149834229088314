/**
 * Formats a phone number according to Binotel rules.
 * @param {string} phone - The phone number to format.
 * @returns {string|boolean} - The formatted phone number or false if the number is invalid.
 */
export async function formatBinotelNumber(phone) {
    const { PhoneNumberUtil, PhoneNumberFormat } = await import('google-libphonenumber');
    const util = PhoneNumberUtil.getInstance();

    let parsedNumber;
    try {
        parsedNumber = util.parse(phone, 'UA');
    } catch (e) {
        return false;
    }

    if (!util.isValidNumber(parsedNumber)) {
        return false;
    }

    const country = util.getRegionCodeForNumber(parsedNumber);

    let formattedPhone;
    if (country !== 'UA') {
        formattedPhone = util.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);
        formattedPhone = phone.replace('+', '00');
        formattedPhone = phone.replace(/[\s\-()]/g, '');
    } else {
        formattedPhone = `0${util.getNationalSignificantNumber(parsedNumber)}`;
    }

    return formattedPhone;
}

export function getPhoneLength(country) {
    return ({
        UA: 12,
        MX: 13,
        KZ: 11,
        UZ: 12,
        PL: 11,
        US: 11,
        KG: 12,
        GE: 12,
        AZ: 12,
        ZA: 11,
        DE: 13,
        IN: 12,
        CO: 12,
        PE: 11,
        MD: 11,
        ES: 11,
        AM: 11,
        CL: 11,
        GB: 12,
        TJ: 12,
        PH: 12,
        RO: 11,
        NG: 13,
        AR: 13,
        LV: 11,
        KH: 11,
        TR: 12,
        GT: 11,
        TH: 11,
        VE: 12,
        ID: 11,
        DO: 11,
        BR: 13,
        EG: 12,
        EC: 12,
        PK: 12,
        NL: 11,
        CA: 11,
        EE: 11,
        AU: 11,
        CR: 11,
        SA: 12,
        FR: 11,
        BO: 11,
        KE: 12,
        CZ: 12,
        AE: 12,
        CN: 13,
        IL: 12,
        TZ: 12,
        CU: 10,
        KR: 12,
        LK: 11,
        PA: 11,
        PT: 12,
        NI: 11,
        BE: 11,
        MY: 11,
        VN: 11,
        CY: 11,
        HN: 11,
        QA: 11,
        GR: 12,
        MV: 10,
        SV: 11,
        IT: 12,
        LT: 11,
        SG: 10,
        TM: 11,
        IQ: 13,
        IE: 12,
        SO: 11,
        AL: 12,
        AT: 11,
        MA: 12,
        PY: 12,
        JM: 11,
        NA: 12,
        UY: 11,
        FI: 12,
        MT: 11,
        NP: 13,
        MU: 11,
        SE: 11,
        BG: 11,
        CH: 11,
        GH: 12,
        HU: 11,
        NZ: 11,
    })[country];
}

/**
 * Returns an array of phone numbers with href links for rendering in UI.
 * @param {object} contacts - The contacts object containing phone numbers for different countries and locales.
 * @param {string} locale - The locale code (e.g. "en", "fr").
 * @param {string} country - The country code (e.g. "US", "FR").
 * @returns {Array} - An array of objects with "value" (phone number) and "href" (tel: link) properties.
 */
export function getPhonesToRender(contacts, locale, country) {
    const { phones, langPhones } = contacts;
    // У langPhones дефолтні номери для локалі, якщо не вдалось визначити країну
    const phonesByCountry = country && phones[country] ? phones[country] : langPhones[locale];
    const phonesToRender = phonesByCountry?.numbers || [];

    return phonesToRender.map((number) => ({
        value: number,
        href: `tel:${number.replace(/[^+\d]/g, '')}`,
    }));
}
