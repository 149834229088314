/* eslint no-underscore-dangle: ["error", { "allow": ["_hsq"] }] */

export function identifyVisitor(email) {
    window._hsq = window._hsq || [];
    window._hsq.push(['identify', { email }]);
}

export function trackPageView(url) {
    window._hsq = window._hsq || [];

    window._hsq.push(['setPath', url]);
    window._hsq.push(['trackPageView']);

    /* eslint-disable-next-line no-undef */
    if (window.ga) {
        window.ga('send', 'pageview', url);
    }
    /* eslint-disable-next-line no-undef, radix */
    if (window.Intercom) {
        window.Intercom('update', { last_request_at: Math.floor((new Date()).getTime() / 1000) });
    }
}
